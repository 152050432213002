import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../shared/layouts/Navbars/DashboardNavbar";
import DataTable from "../../../shared/components/Tables/DataTable";
import SoftButton from "../../../assets/components/SoftButton";

import dataTableData from "./data/TableDataProfiles";

function ProfilesPage(){
    return (<DashboardLayout>
        <DashboardNavbar />
        <SoftButton variant="outlined" color="info" href="/profiles/connect">Connect New Profile</SoftButton>
        <DataTable entriesPerPage={false} table={dataTableData} showTotalEntries={false} canSearch={true} />
    </DashboardLayout>)
}

export default ProfilesPage;
