import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import auth0 from 'auth0-js';

const initialState = {
  webAuth: new auth0.WebAuth({
    domain:       process.env.REACT_APP_AUTH0_DOMAIN,
    clientID:     process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience:     process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    redirectUri:  `${process.env.REACT_APP_WEB_APP_URL}dashboard`,
    scope: 'openid profile email'
  })
}

export const webAuth = createSlice({
  name: 'webAuth',
  initialState,
  reducers: {
  }
})

export function signup(userData) {
  initialState.webAuth.signup({
    connection: 'Username-Password-Authentication',
    email: userData.email,
    password: userData.password,
    //user_metadata: { plan: 'silver', team_id: 'a111' }
  }, function (err) {
      if (err) return alert('Something went wrong: ' + err.message);
      return alert('success signup without login!')
  });
}

export function login(userData, callback) {
  initialState.webAuth.login({
    realm: 'Username-Password-Authentication',
    email: userData.email,
    password: userData.password
  }, function (err) {
      if (err) {
        return console.error('Something went wrong: ' + err.message);
      }
  });
}

export function setSession(authResults, callback) {
  let expiresAt = JSON.stringify(
    authResults.expiresIn * 1000 + new Date().getTime() + 4800000
  )

  localStorage.setItem("access_token", authResults.accessToken)
  localStorage.setItem("id_token", authResults.idToken)
  localStorage.setItem("expires_at", expiresAt)
  
  // trying to get user info
  initialState.webAuth.client.userInfo(authResults.accessToken, (err, user) => {
    if (!callback) throw new Error("Callback undefined");
    else if (err) callback(err);
    else {
      localStorage.setItem("user_info", JSON.stringify(user))
      callback(undefined)
    }
  })
}

export function handleAuthentication(callback) {
  initialState.webAuth.parseHash((errParseHash, authResults) => {
    if (!callback) {
      throw new Error("Callback undefined")
    }
    console.log("AUUTH:", authResults);
    if (errParseHash) {
      callback(errParseHash)
    }
    else if (authResults && authResults.accessToken && authResults.idToken) {
      setSession(authResults, res => {
        callback(res)
      })
    } else {
      callback(new Error("No access token returned"))
    }
  })
}

export function renewSession() {
  initialState.webAuth.checkSession({}, (err, authResults) => {
    if (authResults && authResults.accessToken && authResults.idToken) {
      setSession(authResults)
    }
    else if (err) {
      logout()
    }
  })
}

export function isAuthenticated() {
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"))
  return new Date().getTime() < expiresAt
}

export function logout() {
  localStorage.removeItem("access_token")
  localStorage.removeItem("id_token")
  localStorage.removeItem("expires_at")
  localStorage.removeItem("user_info")
  localStorage.removeItem("user_name")
  localStorage.removeItem("nonce")
  localStorage.removeItem("picture")

  initialState.webAuth.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_WEB_APP_URL
  });
}

export function sessionExpired() {
  localStorage.removeItem("access_token")
  localStorage.removeItem("id_token")
  localStorage.removeItem("expires_at")
  localStorage.removeItem("user_info")
  localStorage.removeItem("user_name")
  localStorage.removeItem("nonce")
  localStorage.removeItem("picture")

  initialState.webAuth.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: process.env.REACT_APP_WEB_APP_URL
  });
}

export default webAuth.reducer;
