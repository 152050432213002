/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../assets/components/SoftBox";
import SoftTypography from "../../../assets/components/SoftTypography";
import SoftInput from "../../../assets/components/SoftInput";
import SoftButton from "../../../assets/components/SoftButton";

// Authentication layout components
import SocializaLayout from "../components/SocializaLayout";

// Images
import bgAuth from "../../../assets/images/svg/bg-auth.svg";
import socializaLogo from "../../../assets/images/svg/socializa.svg";

import {
  signup,
  handleAuthentication,
} from "../../../redux/reducers/auth0";

function Basic(image) {
  const [agreement, setAgremment] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSetAgremment = () => setAgremment(!agreement);
  const handleSetName = (value) => setName(value);
  const handleSetEmail = (value) => setEmail(value);
  const handleSetPassword = (value) => setPassword(value);

  const setSignUp = () => {
    if (name && email && password) {
      signup({
        email: email,
        password: password,
        //user_metadata: { plan: 'silver', team_id: 'a111' }
      });
    }
  }

  return (
    <SocializaLayout>
      <Card
        sx={{
          backgroundImage: (`url(${bgAuth})`),
          backgroundPosition: "-2rem -1rem",
          backgroundRepeat: "no-repeat",
        }}
      >
        <SoftBox 
          p={3}
          mb={1}
          textAlign="center"
        >
          <SoftBox component="img" src={socializaLogo} alt="" pt={2}/>
          <SoftTypography variant="h1" fontWeight="bold" pt={7}>
            Join us today
          </SoftTypography>
          <SoftTypography variant="h6" fontWeight="medium">
            Enter your email and password to register
          </SoftTypography>
        </SoftBox>
        <SoftBox p={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput type="text" placeholder="Name" onChange={(e) => handleSetName(e.target.value)} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="email" placeholder="Email" onChange={(e) => handleSetEmail(e.target.value)} />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" placeholder="Password" onChange={(e) => handleSetPassword(e.target.value)} />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                Terms and Conditions
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                //component={Link}
                onClick={setSignUp}
                //to="/dashboard"
                variant="gradient"
                color="info"
                fullWidth
              >
                sign up
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authenticate"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </SocializaLayout>
  );
}

export default Basic;