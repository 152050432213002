import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../shared/layouts/Navbars/DashboardNavbar";
import { Card, Grid } from "@mui/material";
import SoftBox from "../../../assets/components/SoftBox";
import SoftInput from "../../../assets/components/SoftInput";
import SoftTypography from "../../../assets/components/SoftTypography";
import { WithContext as ReactTags } from "react-tag-input";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import SoftButton from "../../../assets/components/SoftButton";
import SpecialCheckbox from "./specialCheckbox";
import * as React from "react";
import Workspaces from "./workspaces";

function InviteUsersPage() {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);   
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Card>
            <SoftTypography p={2}>Invite User</SoftTypography>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={2}
            >
              <SoftInput type="email" placeholder="Email" />
            </SoftBox>
            <SoftBox p={2}>
              <SoftInput type="text" placeholder="name" />
            </SoftBox>
            <SoftBox p={2}>
              <SoftInput type="text" placeholder="lastname" />
            </SoftBox>
            <SoftBox p={2}>
              <ReactTags
                tags={[{ id: "1", text: "Apples", className: "red" }]}
              ></ReactTags>
            </SoftBox>
          </Card>
        </Grid>

        <Grid item xs={6}>
          {" "}
          <Card>
            <SoftTypography p={2}>Permissions</SoftTypography>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              p={4}
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Select all workspaces"
                />

                <Workspaces />

                <FormControlLabel
                  control={<Checkbox defaultChecked checked={checked} onChange={handleChange} />}
                  label="Schedule Posts"
                />

                <SpecialCheckbox isLoggedIn={checked} />

                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Create Tasks"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Access Social Inbox"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Invite Users"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Connect/Manage Profiles"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Access Analytics"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Create PDF Reports"
                />
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Manage Link-in-Bio"
                />
              </FormGroup>
            </SoftBox>
            <SoftBox p={2}>
              <SoftButton color="info">Send Invitation</SoftButton>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default InviteUsersPage;
