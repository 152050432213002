import SignIn from "./layouts/authentication/login"
import Main from "./layouts/dashboards/main"
import Pricing from "./layouts/sales/pricing"
import Shop from "./shared/components/Icons/Shop"
import SignUp from "./layouts/authentication/signup"
import Calendar from "./layouts/calendar"
import Profiles from "./layouts/profiles/listProfiles"
import ConnectProfiles from "./layouts/profiles/connectProfiles"
import UsersPage from "./layouts/users/listUsers"
import InviteUsersPage from "./layouts/users/inviteUsers"
import TwitterRedirect from "./layouts/profiles/connectProfiles/handleConnected/twitter"

const pageRoutes = [
  {
    type: "collapse",
    name: "Main",
    key: "main",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/dashboard",
        component: <Main />,
      },
      {
        name: "Publisher",
        key: "publisher",
        route: "/publisher",
        component: <Calendar />,
      }
    ],
  },
  {
    name:"Prueba",
    key:"huevos1",
    route:"/huevudos1",
    component: <TwitterRedirect />,
  },
  {
    type: "collapse",
    name: "Auth",
    key: "auth",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "LogIn",
        key: "log",
        route: "/",
        component: <SignIn />,
      },
      {
        name: "SignUp",
        key: "signup",
        route: "/signup",
        component: <SignUp />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Pricing",
    key: "Sales",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Prices",
        key: "price",
        route: "/pricing",
        component: <Pricing />,
      }
    ],
  },{
    type: "collapse",
    name: "Profiles",
    key: "profiles",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Profiles List",
        key: "profileL",
        route: "/profiles",
        component: <Profiles />,
      },
      {
        name: "Connect Profiles",
        key: "connectP",
        route: "/profiles/connect",
        component: <ConnectProfiles />,
      }
    ]
  },{
    type: "collapse",
    name: "Users",
    key: "users",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Users List",
        key: "users",
        route: "/users/list",
        component: <UsersPage />,
      },
      {
        name: "Invite Users",
        key: "invite",
        route: "/users/invite",
        component: <InviteUsersPage />,
      },
    ]
  }
  ];

const sideNavRoutes = [
  {
    type: "collapse",
    name: "Main",
    key: "main",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <Main />,
      },
      {
        name: "Publishing",
        key: "publishing",
        route: "/publishing",
        component: <Calendar />,
      }
    ],
  },
  {
    type: "collapse",
    name: "Profiles",
    key: "profiles",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Profiles List",
        key: "profileL",
        route: "/profiles",
        component: <Profiles />,
      },
      {
        name: "Connect Profiles",
        key: "connectP",
        route: "/profiles/connect",
        component: <ConnectProfiles />,
      }
    ]
  },{
    type: "collapse",
    name: "Users",
    key: "users",    
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Users List",
        key: "users",
        route: "/users/list",
        component: <UsersPage />,
      },
      {
        name: "Invite Users",
        key: "invite",
        route: "/users/invite",
        component: <InviteUsersPage />,
      },
    ]
  }
]

export {pageRoutes, sideNavRoutes};