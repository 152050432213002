/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../assets/components/SoftBox";
import SoftTypography from "../../../assets/components/SoftTypography";
import SoftInput from "../../../assets/components/SoftInput";
import SoftButton from "../../../assets/components/SoftButton";

// Authentication layout components
import SocializaLayout from "../components/SocializaLayout";
import Separator from "../../../shared/layouts/Separator";

// Images
import bgAuth from "../../../assets/images/svg/bg-auth.svg";
import socializaLogo from "../../../assets/images/svg/socializa.svg";

import {
  logout,
  login,
  handleAuthentication,
} from "../../../redux/reducers/auth0";

const handleSignInInfo = () => {
  handleAuthentication((err) => {
    if (!err) {
      let uInfo = localStorage.getItem("user_info");
      console.log(uInfo);
      //getNonce()
    } else {
      console.log(err);

      // logout
    }
  });
};

function Basic(image) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleSetEmail = (value) => setEmail(value);
  const handleSetPassword = (value) => setPassword(value);

  useEffect(() => {
    let id_token = localStorage.getItem("id_token");
    if (id_token) {
      //redireccion
    }
  }, []);

  const setlogIn = () => {
    if (email && password) {
      login({ email: email, password: password });
    } else {
      logout();
    }
  };

  return (
    <SocializaLayout>
      <Card
        sx={{
          backgroundImage: `url(${bgAuth})`,
          backgroundPosition: "-2rem -1rem",
          backgroundRepeat: "no-repeat",
        }}
      >
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftBox component="img" src={socializaLogo} alt="" pt={2} />
          <SoftTypography variant="h1" fontWeight="bold" pt={7}>
            Welcome
          </SoftTypography>
        </SoftBox>
        <SoftBox p={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput
                type="email"
                placeholder="Email"
                onChange={(e) => handleSetEmail(e.target.value)}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                type="password"
                placeholder="Password"
                onChange={(e) => handleSetPassword(e.target.value)}
              />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;Remember me
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                //component={Link}
                //to="/dashboard"
                onClick={setlogIn}
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </SoftButton>
            </SoftBox>
            <Separator />
            <SoftBox mt={1} mb={3}>
              <SoftButton
                component={Link}
                to="/signup"
                variant="gradient"
                color="dark"
                fullWidth
              >
                sign up
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </SocializaLayout>
  );
}

export default Basic;
