// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import SoftTypography from "../../../../assets/components/SoftTypography";
import borders from "../../../../assets/theme/base/borders";
import SoftButton from "../../../../assets/components/SoftButton";

import image from "../../../../assets/images/meeting.jpg"

function NextEvents() {
  const { borderWidth, borderColor } = borders;

  return (
    <Card sx={{ height: "100%" }} id="draggable-container">
      <SoftBox py={2} px={2} borderBottom={`${borderWidth[1]} solid ${borderColor}`}>
        <SoftTypography variant="h6" fontWeight="medium">
          Next events
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2} borderBottom={`${borderWidth[1]} solid ${borderColor}`}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SoftButton variant="gradient" color="info">
              Upload Media
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
          <Grid item xs={4}>
            <SoftBox className="dc-event" component="img" src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available", borderRadius: "5px" }}/>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default NextEvents;
