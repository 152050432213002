// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import SoftTypography from "../../../../assets/components/SoftTypography";

import "./style.css";
import React from "react";
import PropTypes from "prop-types";

import ComposerEditor from "./Editor";
import ComposerPreview from "./Preview";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <SoftBox>
          {children}
        </SoftBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ComposerModal({show, onCloseButtonClick, event, calendar}) {

  const [postMessage, setPostMessage] = React.useState("");

  if (!show) {
    return null
  }

  function handlePostMessageChange(text) {
    setPostMessage(text)
  }

  return (
    <div className="modal-wrapper">
      <SoftBox 
        sx={{
          width: "80%"
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ComposerEditor postMessage={postMessage} setPostMessage={handlePostMessageChange} onCloseButtonClick={onCloseButtonClick} event={event} calendar={calendar}/>
          </Grid>
          <Grid item xs={6}>
            <ComposerPreview postMessage={postMessage}/>
          </Grid>
        </Grid>
      </SoftBox>
    </div>
  );
}

export default ComposerModal;
