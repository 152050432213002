/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import { Grid } from "@mui/material";

function ConnectCard({ image, action, onClick, }) {
    console.log("Action",action)
    console.log("Click",onClick)
  return (
    <Card>
      <Grid container>
        <Grid item xs={4}>
          <SoftBox my={2} mx={2}>
            {onClick===undefined?(action.type === "internal" ? (
              <Link to={action.route} sx={{display: "flex"}}>
                <SoftBox component="img" src={image} width="10rem" borderRadius="lg" />
              </Link>
            ) : (
              <MuiLink href={action.route} target="_blank" rel="noreferrer" sx={{display: "flex"}}>
                <SoftBox component="img" src={image} width="10rem" borderRadius="lg" />
              </MuiLink>
            )):(
                <SoftBox sx={{display: "flex",cursor:"pointer"}} onClick={onClick} component="img" src={image} width="10rem" borderRadius="lg" />
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the DefaultBlogCard
ConnectCard.propTypes = {
  image: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
  }),
  onClick:PropTypes.any
};

export default ConnectCard;
