import { useState, useCallback } from "react";
import update from 'immutability-helper';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DnDItem } from "./Card";


function Dragndrop({items}) {

  const [workspaces, setWorkspaces] = useState(items)

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setWorkspaces((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback((workspace, index) => {
    return (
      <DnDItem
        key={workspace.id}
        index={index}
        id={workspace.id}
        text={workspace.text}
        moveCard={moveCard}
      />
    )
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <div>{workspaces.map((workspace, i) => renderCard(workspace, i))}</div>
    </DndProvider>
  )
}

export default Dragndrop;