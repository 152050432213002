/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbar from "../../../../shared/layouts/Navbars/DefaultNavbar";
import PageLayout from "../../../../shared/layouts/PageLayout";

// Authentication layout components
import Footer from "../../../../shared/layouts/Footer";

// Soft UI Dashboard PRO React page layout routes
import pageRoutes from "../../../../routes/navbar-routes";

function BasicLayout({ title, description, image, children }) {
  return (
    <PageLayout>
      <SoftBox
        width="calc(100% - 2rem)"
        minHeight="30vh"
        borderRadius="lg"
      >
      </SoftBox>
      <SoftBox mt={{ xs: -26, lg: -24 }} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
};

export default BasicLayout;