import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Card, Grid } from '@mui/material'
import SoftBox from '../../assets/components/SoftBox'
import SoftTypography from '../../assets/components/SoftTypography'
import { Box } from '@mui/system'

export const DnDItem = ({ id, text, index, moveCard }) => {
  const ref = useRef(null)
  const style = {
    padding: '0.5rem 0',
    cursor: 'move',
  }

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{...style, opacity}} data-handler-id={handlerId}>
      <Card>
        <SoftBox py={1}>
          <Grid container  px={2}>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "inline-block",
                  marginTop: "0.3em",
                  width: "1.5rem",
                  height: "1.5rem",
                  background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <SoftTypography variant="h6" fontWeight="medium" sx={{display: "inline-block"}}>
                {text}
              </SoftTypography>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </div>
  )
}