import Shop from "../../shared/components/Icons/Shop";

const pageRoutes = [
    {
      name: "Dashboards",
      key: "dashboards",
      icon: <Shop size="12px" color="white" />,
      collapse: [
        {
          name: "Main",
          key: "main",
          route: "/",
        },
        {
          name: "Automotive",
          key: "automotive",
          route: "/dashboards/automotive",
        },
        {
          name: "Smart Home",
          key: "smart-home",
          route: "/dashboards/smart-home",
        },
        {
          name: "VR Default",
          key: "vr-default",
          route: "/dashboards/virtual-reality/default",
        },
        {
          name: "VR Info",
          key: "vr-info",
          route: "/dashboards/virtual-reality/info",
        },
        { name: "CRM", key: "crm", route: "/dashboards/crm" },
      ],
    }
  ];
  export default pageRoutes;