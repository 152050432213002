// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import SoftTypography from "../../../../assets/components/SoftTypography";
import SoftInput from "../../../../assets/components/SoftInput";
import borders from "../../../../assets/theme/base/borders";
import SoftDatePicker from "../../../../assets/components/SoftDatePicker";
import SoftButton from "../../../../assets/components/SoftButton";

// React Select
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "./style.css";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import image from "../../../../assets/images/meeting.jpg"

function ComposerEditor({postMessage, onCloseButtonClick}) {
  const [value, setValue] = React.useState(0);
  const [twTextLimit, setTwTextLimit] = React.useState(280);
  const [fbTextLimit, setFbTextLimit] = React.useState(2200);
  const [date, setDate] = React.useState('2023-02-10')
  const [time, setTime] = React.useState('00:00')
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const { borderWidth, borderColor } = borders;

  // TABS
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // SELECT
  const animatedComponents = makeAnimated();
  const profiles = [
    { value: 'Profile 1', label: 'VetFamily'},
    { value: 'Profile 3', label: 'PETshop'},
    { value: 'Profile 2', label: 'QuickStyle'}
  ]

  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  return (
    <Card>
      <SoftBox
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        p={2}
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Post preview
        </SoftTypography>
      </SoftBox>
      <SoftBox px={2} py={1}>
        <SoftInput
          className="TextArea"
          multiline
          value={postMessage}
        />
        <SoftBox className="dc-event" component="img" mt={3.5} src={image} alt={""} sx={{ maxWidth: "-webkit-fill-available" }}/>
      </SoftBox>
    </Card>
  );
}

export default ComposerEditor;
