import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../shared/layouts/Navbars/DashboardNavbar";
import DataTable from "../../../shared/components/Tables/DataTable";
import SoftButton from "../../../assets/components/SoftButton";

import dataTableData from "./data/TableDataUsers";

function UsersPage() {
    return (<DashboardLayout>
        <DashboardNavbar />
        <SoftButton variant="outlined" color="info" href="/users/invite">Invite Users</SoftButton>
        <DataTable table={dataTableData} showTotalEntries={false} canSearch={true} />
    </DashboardLayout>)
}

export default UsersPage;