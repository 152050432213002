import { FormControlLabel, Switch, Alert } from "@mui/material";

function SpecialCheckbox(props) {
  const isLoggedIn = props.isLoggedIn;
  if (isLoggedIn) {
    return (
      <div>
        <FormControlLabel control={<Switch />} label="Require Approval" />
        <Alert severity="warning">
          This is a warning alert — check it out!
        </Alert>
      </div>
    );
  }
  return "";
}

export default SpecialCheckbox;
