import React, { useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import SoftTypography from "../../../../assets/components/SoftTypography";
import SoftInput from "../../../../assets/components/SoftInput";
import borders from "../../../../assets/theme/base/borders";
import SoftDatePicker from "../../../../assets/components/SoftDatePicker";
import SoftButton from "../../../../assets/components/SoftButton";

// React Select
import Select from "react-select";
import makeAnimated from "react-select/animated";

// Emoji Picker
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";


import "./style.css";
import moment from "moment/moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <SoftBox>
          {children}
        </SoftBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ComposerEditor({postMessage, setPostMessage, onCloseButtonClick, event, calendar}) {
  const [value, setValue] = React.useState(0);
  const [twTextLimit, setTwTextLimit] = React.useState(280);
  const [fbTextLimit, setFbTextLimit] = React.useState(2200);
  const [date, setDate] = React.useState(moment(event.start).format('YYYY-MM-DD'))
  const [time, setTime] = React.useState(moment(event.start).format('HH:mm'))
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [showEmoji, setShowEmoji] = React.useState(false);
  const [postDisabled, setPostDisabled] = React.useState(true);

  const { borderWidth, borderColor } = borders;

  useEffect(() => {
    disableButton()
  }, [date, time])

  // TABS
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // SELECT
  const animatedComponents = makeAnimated();
  const profiles = [
    { value: 'Profile 1', label: 'VetFamily'},
    { value: 'Profile 3', label: 'PETshop'},
    { value: 'Profile 2', label: 'QuickStyle'}
  ]

  // SOCIAL LIMITS
  const handleTextChange = (event) => {
    setPostMessage(event.target.value)
    setTextLimit(event.target.value)
  };
  const setTextLimit = (text) => {
    setTwTextLimit(280 - text.length)
    setFbTextLimit(2200 - text.length);
  }

  // DATE
  const handleDateChange = (event) => {
    let eventDate = moment(event[0])
    setDate(eventDate.format("YYYY-MM-DD"))
  }

  const handleTimeChange = (event) => {
    // HH:mm
    setTime(event.target.value)
  }

  function disableButton() {
    let today = moment();
    let postSchedule = moment(date + " " + time)

    setPostDisabled(today > postSchedule)
  }

  // EMOJI
  const handleEmojiClick = (event) => {
    setShowEmoji(!showEmoji);
  }
  function onEmojiClick (emojiData) {
    setPostMessage(postMessage + emojiData.emoji)
    setTextLimit(postMessage + emojiData.emoji)
  }

  // POST
  const onPostButtonClick = () => {
    let today = moment();
    let postSchedule = moment(date + " " + time)

    let newEvent = event

    if (today < postSchedule) {
      newEvent.start = postSchedule.format('YYYY-MM-DD HH:mm')
      console.log(newEvent)
      calendar.addEvent(newEvent)
      onCloseButtonClick()
    }

  }

  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  return (
    <Card className="card-root">
      <SoftBox
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={3}
      >
        <SoftTypography variant="h6" fontWeight="medium" pb={2}>
          Select Profiles
        </SoftTypography>
        <Select
          onMenuOpen={() => setMenuIsOpen(!menuIsOpen)}
          onMenuClose={() => setMenuIsOpen(!menuIsOpen)}
          styles={{ ...selectStyles }}
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={profiles}
          className="react-select"
        />
      </SoftBox>
      <SoftBox>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab
            id="message"
            label="Message"
            {...a11yProps(0)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <SoftBox
            borderBottom={`${borderWidth[1]} solid ${borderColor}`}
          >
            <SoftInput
              className="TextArea"
              placeholder="Type here..."
              multiline
              rows={5}
              onChange={handleTextChange}
              value={postMessage}
            />
          </SoftBox>
        </TabPanel>
      </SoftBox>
      <SoftBox p={1} m={2} sx={{ backgroundColor: "#F9F9F9" }}>
        <Grid container>
          <Grid item xs={6}>
            <div className="text-limit">
              <span className="iconify" data-icon="logos:twitter"></span>
              <span>{twTextLimit}</span>
              <span className="iconify" data-icon="logos:facebook"></span>
              <span>{fbTextLimit}</span>
            </div>
          </Grid>
          <Grid item xs={6} className="footer-bar-actions">
            <Grid container>
              <Grid item xs={9}></Grid>
              <Grid item xs={1}>
                <button className="footer-bar-button" onClick={handleEmojiClick}>
                  <span className="iconify" data-icon="fontisto:smiley"></span>
                </button>
                {
                  showEmoji ? 
                  (
                    <EmojiPicker
                      onEmojiClick={onEmojiClick}
                      className="emoji-picker"
                      height={350}
                    />
                  ) 
                  :
                  (
                    <></>
                  )
                }
              </Grid>
              <Grid item xs={1}>
                <button className="footer-bar-button">
                  <span className="iconify" data-icon="bi:bookmark-fill" sx={{color: "black"}}></span>
                </button>
              </Grid>
              <Grid item xs={1}>
                <button className="footer-bar-button">
                  <span className="iconify" data-icon="fa6-solid:location-dot" sx={{color: "black"}}></span>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox px={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SoftDatePicker input={{placeholder: 'Select a date', value: date}} onChange={handleDateChange}/>
              </Grid>
              <Grid item xs={6}>
                <SoftInput type="time" value={time} onChange={handleTimeChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SoftButton variant="gradient" color="info" fullWidth onClick={onCloseButtonClick}>
              Close
            </SoftButton>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <SoftButton variant="gradient" color="info" fullWidth onClick={onPostButtonClick} disabled={postDisabled}>
              Post Now
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default ComposerEditor;
