/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../../assets/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "../../../../../shared/components/Cards/PricingCards/DefaultPricingCard";

function PricingCards({ prices }) {
  const [starter, premium, enterprise] = prices;
  return (
    <SoftBox position="relative" zIndex={10} mt={-19} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "warning", label: "starter" }}
            price={{ currency: "$", value: starter }}
            leyend={"Para construir tu estrategia social"}
            description={[{id:1,text:"15 perfiles sociales"},{id:2,text:"Publicaciones ilimitadas"},{id:3,text:"2 usuarios"}]}
            action={{
              type: "internal",
              route: "/",
              color: "dark",
              label: "join",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "premium" }}
            price={{ currency: "$", value: premium }}
            leyend={"Para hacer crecer su agencia o marca"}
            description={[{id:1,text:"15 perfiles sociales"},{id:2,text:"Publicaciones ilimitadas"},{id:3,text:"5 usuarios"}]}
            action={{
              type: "internal",
              route: "/",
              color: "info",
              label: "join",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: "enterprise" }}
            price={{ currency: "$", value: enterprise }}
            leyend={"Para administrar redes sociales con un equipo mas grande"}
            description={[{id:1,text:"15 perfiles sociales"},{id:2,text:"Publicaciones ilimitadas"},{id:3,text:"10 usuarios"}]}
            action={{
              type: "internal",
              route: "/",
              color: "dark",
              label: "join",
            }}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
