import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../shared/layouts/Navbars/DashboardNavbar";
import SoftTypography from "../../../assets/components/SoftTypography";
import SimpleBlogCard from "../../../shared/components/Cards/BlogCards/DefaultBlogCard";
import Grid from "@mui/material/Grid";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useState, useEffect } from "react";
import axios from "axios";
import { Stack, CircularProgress } from "@mui/material";
import ConnectCard from "./connectComponent";
const appId = "3074854639404570";

const _profiles = [];

function checkLoginState() {
  // eslint-disable-next-line
  FB.getLoginStatus(function (response) {
    console.log(response);
  });
}

function ConnectProfilesPage() {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const loadFB = async () => {
    FacebookLoginClient.clear();
    await FacebookLoginClient.loadSdk("es-419");
    FacebookLoginClient.init({ appId: appId, version: "v18.0" });
  };
  useEffect(() => {
    loadFB();
    axios
      .get(
        "https://rem7advf80.execute-api.us-east-2.amazonaws.com/v1/authorization_url"
      )
      .then((response) => {
        const twitterRoute = JSON.parse(response.data.body);
        const setProfileT = {
          image: "https://cdn-icons-png.flaticon.com/512/3670/3670151.png",
          title: "twitter",
          action: {
            type: "external",
            route: twitterRoute.authorization_url,
            color: "info",
            label: "Connect",
          },
        };

        const indexT = profiles.findIndex(
          (object) => object.title === setProfileT.title
        );

        if (indexT === -1) {
          setProfiles((oldArray) => [...oldArray, setProfileT]);
        }
        const setProfileF = {
          image:
            "https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/240px-Facebook_f_logo_%282021%29.svg.png",
          title: "facebook",
          onClick: () => {
            FacebookLoginClient.login(console.log, {
              scope: "public_profile, email",
            });
          },
        };
        const indexF = profiles.findIndex(
          (object) => object.title === setProfileF.title
        );
    
        if (indexF === -1) {
          setProfiles((oldArray) => [...oldArray, setProfileF]);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Twitter route:", error);
      });
  }, []);

  if (isLoading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress color="info" />
      </Stack>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftTypography>Connect your profiles</SoftTypography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "50vh" }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {
            // eslint-disable-next-line array-callback-return
            profiles.map(({ image, action, onClick, title }) =>
              onClick === undefined ? (
                <Grid item  key={title}>
                  <ConnectCard image={image} action={action} />
                </Grid>
              ) : (
                <Grid item  key={title}>
                  <ConnectCard image={image} onClick={onClick} />
                </Grid>
              )
            )
          }
        </Grid>
      </Stack>
    </DashboardLayout>
  );
}

export default ConnectProfilesPage;
