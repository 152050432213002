
import Grid from "@mui/material/Grid";
import SoftTypography from "../../../../../assets/components/SoftTypography";
import SoftAvatar from "../../../../../assets/components/SoftAvatar";

function ListProfileCard({text, src, size, variant }){
    return (
        <Grid container spacing={4}>
            <Grid item xs={6} md={4}>
            <SoftAvatar src={src} bgColor={src ? "transparent" : "info"} size={size ? size: "md"} />
            </Grid>
            <Grid item xs={6} md={8}>
                <SoftTypography variant={variant ? variant: "p"} component="div" sx={{mt:'12%'}}>
                {text}
                </SoftTypography>
            </Grid>
        </Grid>
    )
}

export default ListProfileCard;