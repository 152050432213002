/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SoftTypography from "../../../../assets/components/SoftTypography";
import Icon from "@mui/material/Icon";
import ListProfileCard from "../../../../shared/components/Cards/ProfileCard/ListProfileCard";
const dataTableData = {
  columns: [
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          User name
        </SoftTypography>
      ),
      accessor: "name",
      width: "40%",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Role
        </SoftTypography>
      ),
      accessor: "role",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Email
        </SoftTypography>
      ),
      accessor: "email",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Permission
        </SoftTypography>
      ),
      accessor: "permission",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Actions
        </SoftTypography>
      ),
      accessor: "actions",
      width: "20%",
      align: "center",
    }
  ],

  rows: [
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Daenerys Targeryan"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Jon Snow"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Aryan Stark"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Sansa Stark"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Cercei Lannister"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"Margery Tyrell"} size={"md"} />,
      role: "PlaceHolder",
      email: "email@example.com",
      permission: "Active",
      actions: <p>actions</p>
    },
  ],
};

export default dataTableData;
