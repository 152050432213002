/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../assets/components/SoftBox";
import SoftTypography from "../../../assets/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../shared/layouts/Navbars/DashboardNavbar";
import Footer from "../../../shared/layouts/Footer";
import MiniStatisticsCard from "../../../shared/components/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "../../../shared/components/Tables/SalesTable";
import ReportsBarChart from "../../../shared/components/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "../../../shared/components/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard PRO React base styles
import typography from "../../../assets/theme/base/typography";
import breakpoints from "../../../assets/theme/base/breakpoints";

// Data
import profilesListData from "./data/profilesListData";
import reportsBarChartData from "./data/reportsBarChartData";
import gradientLineChartData from "./data/gradientLineChartData";
import { blue } from "@mui/material/colors";
import ProfilesList from "../../../examples/Lists/ProfilesList";
import { useEffect, useState } from "react";
import { Stack, CircularProgress } from "@mui/material";
import { logout, handleAuthentication } from "../../../redux/reducers/auth0";
import axios from "axios";

function Default() {
  const { values } = breakpoints;
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let user_info = localStorage.getItem("user_info");
    if (!user_info) {
      handleAuthentication((err) => {
        if (err) {
          console.log(err);
          // logout()
        } else {
          user_info = localStorage.getItem("user_info");
          console.log("isnull", user_info);
          if (user_info) {
            /**
             * 
            axios
              .post("http://localhost:3001/user/v1/security/login", JSON.parse(user_info))
              .then((res) => {
                console.log(res);
                setLoading(false);
              });
             */
            setLoading(false)
          }
        }
      });
    } else {
      setLoading(false)
    }
  }, []);

  if (loading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress color="info" />
      </Stack>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={3}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{
                      text: "Total Audience in last 30 days",
                      fontWeight: "bold",
                    }}
                    //percentage={{ color: "success", text: "+55%" }}
                    count="50,000"
                    icon={{ color: "info", component: "record_voice_over" }}
                    style={{ boxShadow: "0px 4px" }}
                    shadowColor={"rgba(42, 95, 241,0.5)"}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <MiniStatisticsCard
                  title={{
                    text: "Engagement rate in last 30 days",
                    fontWeight: "bold",
                  }}
                  count="23%"
                  //percentage={{ color: "success", text: "+3%" }}
                  icon={{ color: "info", component: "visibility" }}
                  shadowColor={"rgba(10, 213, 255,0.5)"}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{
                      text: "New Followers in last 30 days",
                      fontWeight: "bold",
                    }}
                    count="+3,462"
                    //percentage={{ color: "error", text: "-2%" }}
                    icon={{ color: "info", component: "person" }}
                    shadowColor={"rgba(80, 216, 144,0.5)"}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={3}>
                <SoftBox mb={3}>
                  <MiniStatisticsCard
                    title={{
                      text: "Ads Spent in last 30 days",
                      fontWeight: "bold",
                    }}
                    count="$103,430"
                    //percentage={{ color: "success", text: "+5%" }}
                    icon={{
                      color: "info",
                      component: "paid",
                    }}
                    shadowColor={"rgba(108, 62, 236,0.5)"}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <SoftBox position="relative">
                <GradientLineChart
                  title="Sales Overview"
                  description={
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox
                        fontSize={size.lg}
                        color="success"
                        mb={0.3}
                        mr={0.5}
                        lineHeight={0}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
                      </SoftBox>
                      <SoftTypography
                        variant="button"
                        color="text"
                        fontWeight="medium"
                      >
                        Published posts in the last 30 days{" "}
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          <></>
                        </SoftTypography>
                      </SoftTypography>
                    </SoftBox>
                  }
                  chart={gradientLineChartData}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ProfilesList title="conversations" profiles={profilesListData} />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
