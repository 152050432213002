import DashboardLayout from "../../../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../../../shared/layouts/Navbars/DashboardNavbar";
import SoftTypography from "../../../../assets/components/SoftTypography";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Stack, CircularProgress } from "@mui/material";

function TwitterRedirect() {
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    const params = new URLSearchParams(hash.replace("#", "?"));
    const accessToken = params.get("access_token");

    if (accessToken) {
      console.log("Access Token:", accessToken);
      // Perform actions with the access token
    }
  }, [location]);

  if (isLoading) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <CircularProgress color="info" />
      </Stack>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Stack direction="row" justifyContent="center" alignItems="center">
        <SoftTypography>Twitter Profile Connected</SoftTypography>
      </Stack>
    </DashboardLayout>
  );
}

export default TwitterRedirect;
