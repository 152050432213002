// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../assets/components/SoftBox";
import SoftTypography from "../../../../assets/components/SoftTypography";

// Custom styles for Calendar
import CalendarRoot from "./CalendarRoot";
import React, { useEffect, useState } from "react";

import "./style.css";

function Calendar({ header, toggleModal, ...rest }) {


  let calendarRef = React.createRef();

  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  useEffect(() => {
    let draggableContainer = document.getElementById("draggable-container")

    new Draggable(draggableContainer, {
      // use class="dc-event" in the drag-n-drop items
      itemSelector: '.dc-event',
      // eslint-disable-next-line no-unused-vars
      eventData: function(event) {
        /*
        { // this object will be "parsed" into an Event Object
          title: 'The Title', // a property!
          start: '2018-09-01', // a property!
          end: '2018-09-02' // a property! 
        }
        */
        return {
          // we do not want to create an event upon drop
          create: false,
          imageUrl: event.attributes.src.value,
        }
      },
    });
  }, [])

  var events = [];

  const eventContent = function(eventInfo, createElement) {
    var innerHtml;
    let eventObject = eventInfo.event;

    if (eventObject.extendedProps.imageUrl) {
      innerHtml = `<img class="fc-img-event" src="${eventObject.extendedProps.imageUrl}">`
      return createElement = { html: `${innerHtml}`}
    }

    return null
  }

  const eventDrop = function (info) {
    let calendarApi = calendarRef.current.getApi();
    let infoDate = new Date(info.date);
    let today = new Date();
    // don not create events on past days
    if (infoDate > today) {
      let event = {
        id: "",
        groupId: "",
        allDay: false,
        start: info.date,
        end: info.date,
        startTime: null,          // The time of day the event starts. hh:mm:ss
        endTime: null,            // The time of day the event ends. hh:mm:ss
        title: "",                // String. The text that will appear on an event
        url: "",                  // String. A URL that will be visited when this event is clicked by the user
        interactive: false,       // Whether or not the event is tabbable. Defaults to true if url is present, false otherwise
        classNames: [],           // String or Array. A single string like 'myclass', a space-separated string like 'myclass1 myclass2', or an array of strings like [ 'myclass1', myclass2' ].
        editable: false,          // Overrides the master editable option for this single event
        display: "auto",          // Allows alternate rendering of the event, like background events. Can be 'auto' (the default), 'block', 'list-item', 'background', 'inverse-background', or 'none'
        color: "transparent",     // String. An alias for specifying the backgroundColor and borderColor at the same time
        textColor: "black",       // Sets an event’s text color just like the calendar-wide eventTextColor option
        extendedProps: {          // Object. A plain object with any miscellaneous properties.
          imageUrl: info.draggedEl.currentSrc
        }
      }
      
      toggleModal(event, calendarApi);
      //calendarApi.addEvent(event);
      
      return info
    }
  }

  return (
    <Card >
      <SoftBox pt={2} px={2} lineHeight={1}>
        {header.title ? (
          <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </SoftTypography>
        ) : null}
        {header.date ? (
          <SoftTypography component="p" variant="button" color="text" fontWeight="medium">
            {header.date}
          </SoftTypography>
        ) : null}
      </SoftBox>
      <CalendarRoot p={2}>
        <FullCalendar
          ref={calendarRef}
          contentHeight={550}
          headerToolbar={{
            left: 'today prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          initialView="dayGridMonth"
          defaultAllDay={false}
          droppable={true}
          editable={false}
          selectable={true}
          eventContent={eventContent}
          drop={eventDrop}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
