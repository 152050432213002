/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../assets/components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../../shared/layouts/PageLayout";

// Pricing page components
import Header from "./components/Header";
import Footer from "./components/Footer";
import PricingCards from "./components/PricingCards";
import SoftTypography from "../../../assets/components/SoftTypography";
import DataTable from "../../../shared/components/Tables/DataTable";
// Data
import dataTableData from "./data/dataTableData";

function PricingPage() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <PageLayout>
      <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
      <Container>
        <PricingCards prices={prices} />
      </Container>
      <SoftBox pt={6} pb={3} px={6}>
        <SoftBox mb={3}>
          <Card>
            <DataTable table={dataTableData} isSorted={false} showTotalEntries={false} />
          </Card>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default PricingPage;
