/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SoftTypography from "../../../../assets/components/SoftTypography";
import Icon from "@mui/material/Icon";
const dataTableData = {
  columns: [
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h5"
          color="info"
          fontWeight="medium"
          textGradient
        >
          Caracteristicas Clave
        </SoftTypography>
      ),
      accessor: "char",
      width: "40%",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h5"
          color="info"
          fontWeight="medium"
          textGradient
        >
          Starter
        </SoftTypography>
      ),
      accessor: "initial",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h5"
          color="info"
          fontWeight="medium"
          textGradient
        >
          Premium
        </SoftTypography>
      ),
      accessor: "premium",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h5"
          color="info"
          fontWeight="medium"
          textGradient
        >
          Enterprise
        </SoftTypography>
      ),
      accessor: "enterprise",
      width: "20%",
      align: "center",
    },
  ],

  rows: [
    {
      char: "Perfiles Sociales",
      initial: "15",
      premium: "15",
      enterprise: "15",
    },
    {
      char: "Publicaciones por Perfil",
      initial: "Ilimitado",
      premium: "Ilimitado",
      enterprise: "Ilimitado",
    },
    {
      char: "Usuarios",
      initial: "2",
      premium: "5",
      enterprise: "10",
    },
    {
      char: "Espacios",
      initial: "2",
      premium: "Ilimitado",
      enterprise: "Ilimitado",
    },
    {
      char: "Almacenamiento",
      initial: "250mb",
      premium: "2GB",
      enterprise: "15GB",
    },
    {
      char: "Gestion de Anuncios",
      initial: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Analitica",
      initial: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Informes PDF",
      initial: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Gestion de Anuncios",
      initial: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Diseño con Canva",
      initial: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Programacion Masiva",
      initial: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Aprobaciones",
      initial: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
    {
      char: "Tareas",
      initial: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      premium: (
        <Icon fontSize="default" color="inherit">
          close
        </Icon>
      ),
      enterprise: (
        <Icon fontSize="default" color="inherit">
          done
        </Icon>
      ),
    },
  ],
};

export default dataTableData;
