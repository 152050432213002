/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../../assets/components/SoftBox";
import SoftTypography from "../../../../../assets/components/SoftTypography";
import SoftAvatar from "../../../../../assets/components/SoftAvatar";
import { Grid } from "@mui/material";

function DefaultBlogCard({ image, category, title, description, author, action }) {
  return (
    <Card>
      <Grid container>
        <Grid item sx={4}>
          <SoftBox my={2} mx={2}>
            {action.type === "internal" ? (
              <Link to={action.route} sx={{display: "flex"}}>
                <SoftBox component="img" src={image} alt={title} width="3rem" borderRadius="lg" />
              </Link>
            ) : (
              <MuiLink href={action.route} target="_blank" rel="noreferrer" sx={{display: "flex"}}>
                <SoftBox component="img" src={image} alt={title} width="3rem" borderRadius="lg" />
              </MuiLink>
            )}
          </SoftBox>
        </Grid>
        <Grid item sx={8}>
          <SoftBox py={2} px={3}>
            {category && (
              <SoftTypography
                variant="caption"
                color={category.color}
                textTransform="uppercase"
                fontWeight="medium"
                textGradient
              >
                {category.label}
              </SoftTypography>
            )}
            <SoftBox display="block" mt={0.5} mb={1}>
              {action.type === "internal" ? (
                <Link to={action.route}>
                  <SoftTypography
                    display="inline"
                    variant="h5"
                    textTransform="capitalize"
                    className="color-background"
                  >
                    {title}
                  </SoftTypography>
                </Link>
              ) : (
                <MuiLink href={action.route} target="_blank" rel="noreferrer">
                  <SoftTypography
                    display="inline"
                    variant="h5"
                    textTransform="capitalize"
                    className="color-background"
                  >
                    {title}
                  </SoftTypography>
                </MuiLink>
              )}
            </SoftBox>
            <SoftTypography variant="body2" component="p" color="text">
              {description}
            </SoftTypography>
            {author && (
              <SoftBox display="flex" alignItems="center" mt={3}>
                <SoftAvatar variant="rounded" src={author.image} alt={author.name} shadow="md" />
                <SoftBox pl={2} lineHeight={0}>
                  <SoftTypography component="h6" variant="button" fontWeight="medium" gutterBottom>
                    {author.name}
                  </SoftTypography>
                  <SoftTypography variant="caption" color="text">
                    {author.date}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Setting default props for the DefaultBlogCard
DefaultBlogCard.defaultProps = {
  category: false,
  author: false,
};

// Typechecking props for the DefaultBlogCard
DefaultBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.shape({
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.oneOfType([
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }),
    PropTypes.bool,
  ]),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultBlogCard;
