/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import SoftTypography from "../../../../assets/components/SoftTypography";
import Icon from "@mui/material/Icon";
import ListProfileCard from "../../../../shared/components/Cards/ProfileCard/ListProfileCard";
const dataTableData = {
  columns: [
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Profile name
        </SoftTypography>
      ),
      accessor: "name",
      width: "40%",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Profile Type
        </SoftTypography>
      ),
      accessor: "pt",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          Nickname
        </SoftTypography>
      ),
      accessor: "nickname",
      width: "20%",
      align: "center",
    },
    {
      Header: (
        <SoftTypography
          py={2}
          variant="h6"
          color="text"
          fontWeight="medium"
          textGradient
        >
          First Connection Date
        </SoftTypography>
      ),
      accessor: "connection",
      width: "20%",
      align: "center",
    },{
        Header: (
          <SoftTypography
            py={2}
            variant="h6"
            color="text"
            fontWeight="medium"
            textGradient
          >
            Last Refresh Date
          </SoftTypography>
        ),
        accessor: "refresh",
        width: "20%",
        align: "center",
      }, {
        Header: (
          <SoftTypography
            py={2}
            variant="h6"
            color="text"
            fontWeight="medium"
            textGradient
          >
            Status
          </SoftTypography>
        ),
        accessor: "status",
        width: "20%",
        align: "center",
      }
  ],

  rows: [
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "Facebook Page",
      nickname: "The User FB",
      connection: "January 31, 2023",
      refresh:"-",
      status:"Active"
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "Instagram Business Profile",
      nickname: "The User IG",
      connection: "January 22, 2023",
      refresh:"-",
      status:"Active"
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "Facebook Ad Account",
      nickname: "The User FB Ads",
      connection: "January 22, 2023",
      refresh:"-",
      status:"Active"
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "Twitter Group",
      nickname: "The User TW",
      connection: "January 22, 2023",
      refresh:"-",
      status:"Needs Refresh"
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "Facebook Group",
      nickname: "The User FBG",
      connection: "January 22, 2023",
      refresh:"-",
      status:"Active"
    },
    {
      name: <ListProfileCard src="https://bit.ly/34BY10g" text={"The User"} size={"sm"} />,
      pt: "LinkedIn Company Page",
      nickname: "The User",
      connection:"January 22, 2023",
      refresh:"December 30, 2022",
      status:"Active"
    },
  ],
};

export default dataTableData;
