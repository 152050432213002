import { useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../assets/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "../../shared/layouts/DashboardLayout";
import DashboardNavbar from "../../shared/layouts/Navbars/DashboardNavbar";

// Calendar application components
import Header from "./components/Header";
import NextEvents from "./components/NextEvents";
import EventCalendar from "./components/Calendar";

import ComposerModal from "./components/ComposerModal"

function Calendar() {
  const [showModal, setShowModal] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [calendarApi, setCalendar] = useState({});

  const toggleModal = function (event, calendarApi) {
    setNewEvent(event)
    setCalendar(calendarApi)
    setShowModal(!showModal);
  }

  /**
   * <SoftBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
          <Header />
        </SoftBox>
   */
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ComposerModal show={showModal} onCloseButtonClick={toggleModal} event={newEvent} calendar={calendarApi}/>
      <SoftBox pt={3}>
        
        <Grid container spacing={3}>
          <Grid item xs={12} xl={3}>
            <SoftBox >
              <NextEvents />
            </SoftBox>
          </Grid>
          <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  initialView="dayGridMonth"
                  events={[]}
                  toggleModal={toggleModal}
                />
              ),
              [[]]
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Calendar;
